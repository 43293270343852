.leaflet-container {
  width: 100vw;
  height: 85vh;
}

.headerImage {
  background-image: url('./images/usace-black-small2.png');
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat; 
  float: left;
  margin-left: 1.0pem;
  width: 45px;
  height: 35px;
  margin: 0px 10px;
}

.headerDescription {
  width: 50px;
}

.leaflet-control-geosearch {
   left: 62px;
   /* top: -10px; */
   padding: 4px;
   width:390px;
   font-size: 14px;
   border-width: 1px;
   border-color: #CCCCCC;
   background-color: #FFFFFF;
   color: #000000;
   border-style: solid;
   border-radius: 2px;
   box-shadow: 0px 0px 5px rgba(66,66,66,.75);
   text-shadow: undefined 0px 0px 5px px rgba(66,66,66,.75);
}

/* leaflet-geosearch-bar */

.leaflet-interactive {
  stroke-width: 3px;
}

#leftbox {
  float:left; 
  /* background:Red; */
  width:40%;
  height:45px;
}
#middlebox{
  float:left; 
  /* background:Green; */
  width:28%;
  height:65px;
  position: relative; 
    left: 0px;
    top: -12px;
    padding:4px;
    background-color: #505d61;
    color: #000;
    border-radius: 2px;
    box-shadow: 0px 1px 10px rgba(66,66,66,.75);
    background-clip: padding-box;
    opacity:0.9;
    font: 14px arial, sans-serif;
    /* z-index:999; */
}
#rightbox{
  float:right;
  /* background:blue; */
  width:25%;
  /* height:280px; */
}