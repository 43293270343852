.leaflet-label {
  background: rgb(235, 235, 235);
  background: rgba(235, 235, 235, 0.81);
  background-clip: padding-box;
  border-color: #777;
  border-color: rgba(0,0,0,0.25);
  border-radius: 4px;
  border-style: solid;
  border-width: 4px;
  color: #111;
  display: block;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 1px 6px;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  white-space: nowrap;
  z-index: 6;
}

.leaflet-control {
  top: -10px;
}
/*
.leaflet-label.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-label:before,
.leaflet-label:after {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: none;
  position: absolute;
  top: 5px;
}
.leaflet-label:before {
  border-right: 6px solid black;
  border-right-color: inherit;
  left: -10px;
}
.leaflet-label:after {
  border-left: 6px solid black;
  border-left-color: inherit;
  right: -10px;
}
.leaflet-label-right:before,
.leaflet-label-left:after {
  content: "";
}
.leaflet-left {
  left:auto;
  right: 5px;
}
.leaflet-right {
    right: 50px;
}
.searchRegion{ 
    position:absolute; 
    left:45%;
    margin-left:-112px;
    top: 70px; 
    width: 224px;
    z-index:999;
  }
  .corpsmap-title-title img {
     position: absolute;
     top: 50%;
     height: 35px;
     margin-top: -19px;
  }*/
.searchInput{
   padding: 4px;
   width:390px;
   font-size: 14px;
   border-width: 1px;
   border-color: #CCCCCC;
   background-color: #FFFFFF;
   color: #000000;
   border-style: solid;
   border-radius: 2px;
   box-shadow: 0px 0px 5px rgba(66,66,66,.75);
   text-shadow: undefined 0px 0px 5px px rgba(66,66,66,.75);
}
.searchInput:focus {
    outline: none !important;
    border:1px solid #3b4649;
    box-shadow: 0 0 10px #1a262a;
}
.instruction{
  width:370px;
  color:#CCC;
}
.resultStyle{
    position: absolute; 
    left: 75px;
    top:0px;
    padding:4px;
    background-color: #505d61;
    color: #000;
    border-radius: 2px;
    box-shadow: 0px 1px 10px rgba(66,66,66,.75);
    background-clip: padding-box;
    opacity:0.9;
    font: 14px arial, sans-serif;
    z-index:999;
  }   
.resultTable{
    margin:10px 0px 0px 10px;
}
.resultTable tr{
    height:40px;
}
.resultTable td{
    vertical-align: middle;
}
.resultContainer{
  width:400px;
  background-color:#fff;
}
